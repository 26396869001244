<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <img class="m-auto" width="200" src="../../../assets/img/anita-besar.png" />
      <!-- <div class="text-dark font-size-32 mb-3 text-center"><strong>Login MODC</strong></div> -->
      <!-- TO DO DUMMY, UNCOMMENT THIS BLOCK -->
      <a-form :model="loginForm" :rules="rules" layout="vertical" class="my-4" @finish="handleFinish"
        @finishFailed="handleFinishFailed">
        <a-form-item name="email">
          <a-input v-model:value="loginForm.email" placeholder="Email" />
        </a-form-item>
        <a-form-item name="password">
          <a-input-password v-model:value="loginForm.password" placeholder="Sandi" />
        </a-form-item>
        <!-- TO DO DUMMY FUNCTION, UNCOMMENT BLOCK/LINE BELOW & COMMENT BLOCK/LINE AFTER -->
        <!-- <a-button type="primary" html-type="submit" class="text-center w-100" :loading="loading"> -->
        <a-button type="primary" html-type="submit" class="btn text-center w-100" :loading="loading">
          <strong>Login</strong>
        </a-button>
      </a-form>
    </div>
    <!-- <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Don't have an account?</span>
      <router-link to="/auth/register" class="vb__utils__link">
        Sign up
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'

export default {
  name: 'ThLogin',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    // const cookies = ref(null)
    const rules = {
      email: [
        {
          required: true,
          message: 'Tolong masukkan email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = async values => {
      console.log(values)
      const data = await store.dispatch('user/LOGIN', { ...values })
      const user = data.user || data
      console.log(data, '<<<<<<DATA')
      localStorage.setItem('accessToken', user.accessToken)
      cookies.set('sessionId', user.accessToken)
      // this.$cookies.set('sessionId', token, null, '/')
      // const sessionId = this.$cookies.get('sessionId')
      // console.log(sessionId)
      store.commit('user/SET_STATE', {
        // name: user.name,
        email: user.email,
        sessionId: user.accessToken,
        role: user.role,
      })
      cookies.set('user', user, null, '/')
      if (user.role === 'siteManager') {
        router.push('/eos-monitoring')
      }
      else if (user.role === 'eos') {
        router.push('/monitoring')
      }
      else if (user.role === 'receptionist') {
        router.push('/receptionist')
      }
      else if (user.role === 'helpDesk') {
        router.push('/request')
      }
      else {
        router.push('/')
      }
      notification.success({
        message: 'Selamat Datang ' + user.name,
        duration: 5,
      })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
    }
  },

  methods: {
    // pushToBanner() {
    // this.$router.push('banner')
    // },
  },
  // data: function () {
  //   return {
  //     rules: {
  //       email: [{ required: true, message: 'Tolong masukkan username!', trigger: 'change' }],
  //       password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
  //     },
  //     loginForm: {
  //       email: 'demo@visualbuilder.cloud',
  //       password: 'VisualBuilder',
  //     },
  //   }
  // },
  // computed: {
  //   ...mapState(['settings']),
  //   loading() {
  //     return this.$store.state.user.loading
  //   },
  // },
  // methods: {
  //   changeAuthProvider(value) {
  //     this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
  //   },
  //   handleFinish(values) {
  //     this.$store.dispatch('user/LOGIN', { payload: values })
  //   },
  //   handleFinishFailed(errors) {
  //     console.log(errors)
  //   },
  // },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
</style>
